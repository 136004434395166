/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { Component } from 'react';
import { auth, storage, refUsers, refCategory, refLocations, refProducts, refPictures, db } from '../config'
import Menu from '../components/Menu';
import Resizer from 'react-image-file-resizer';
import { Helmet } from 'react-helmet'
// import { renderToString } from "react-dom/server";
// import SocialLink from '../components/SocialLink';
// import logo from '../assets/logo.png';
import Spinner from 'react-bootstrap/Spinner';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faFacebookF, faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import { faPhone, faPhoneAlt, faMailBulk, faAt, faUtensils, faMapPin, faMapMarker, faHome, faCalendar, faCalendarAlt, faMobile, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { addDoc, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { getDownloadURL, ref as refOfStorage, uploadBytes, uploadString } from 'firebase/storage';

export let endpoint = 'https://meniuapi.xyz';
// export let endpoint = 'http://127.0.0.1:3001';

export const LocationPageWithParams = () => {
  const params = useParams();
  return <LocationPage params={params} />;
};


class LocationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: "",
      lid: '',
      verified: undefined,
      editmode: false,
      categories: [],
      products: [],
      productsFiltered: [],
      pictures: [],
      search: '',
      location: {},
      infoLocation: {},
      loading: true,
      duplicatedit: false
    }
    // console.clear();
    // console.log(this);

    let slug = this.props.params.slug
    if (slug === undefined) {
      slug = ''
    }
    // vraT8VWGKFTQUGCOb8JO
    if (slug === 'rivo3') {
      // refCategory.where('location_id', '==', 'vraT8VWGKFTQUGCOb8JO').where('menu', '==', 'Bauturi').get().then(refs => {
      //   refs.docs.forEach(ref => {
      //     const data = ref.data();
      //     // refCategory.doc(ref.id).set({
      //     //   location_id: 'ZtNI8jqDVvxIkSzlsOaJ'
      //     // }, { merge: true })
      //     console.log(data);
      //   });
      // });
    }

    // if (slug === 'millenium') {

    //   window.location = 'https://meniu.la/' + slug + '/info';
    // }

    this.action = this.props.params.action;
    if (this.action !== undefined) {
      if (this.action === "info") {
        this.state.infoMode = true;
        this.getLocationBySlug(slug);
      }
    }

    this.state.slug = slug;


    this.fetchLogin();

    this.handleFileChange = this.handleFileChange.bind(this);
    this.loadData = this.loadData.bind(this);
    let clearCache = new URLSearchParams(window.location.search).get("c");
    if (clearCache !== undefined && clearCache !== null) {
      this.loadData(1);
    } else {
      this.loadData();
    }
  }

  getLocationBySlug(slug) {
    getDocs(query(refLocations, where('slug', '==', slug))).then(refs => {
      refs.docs.forEach(ref => {
        const data = ref.data();

        this.setState({
          infoLocation: data,
          className: data.className,
          loading: false
        });
      });
    });
  }

  loadData = (nocache) => {
    console.log('load data');

    Axios.post(`${endpoint}/api/db/getLocationBySlug`, {
      slug: this.state.slug,
      nocache
    })
      .then((response) => {
        debugger
        console.log(response);


        let data = response.data;

        this.setState({
          loading: false,
          lid: data.location.lid,
          editmode: data.location.uid === this.state.uid,
          // className: data.className,
          className: data.className,
          location: data.location,
          categories: data.categories,
          products: data.products,
          productsFiltered: data.products,
          pictures: data.pictures
        });
      })
      .catch(err => {
        console.log(err);
        console.log(err.response.data)
        debugger

      });
  }

  // clearCache() {
  //   Axios.post(`${endpoint}/api/db/getLocationBySlug`, {
  //     slug: this.state.slug,
  //     nocache: 1
  //   })
  // }

  search(text) {
    this.state.search = text;

    if (text.length < 3 && text !== '') {
      return;
    }

    let products = [...this.state.products];
    products = products.filter((item) => {
      if (item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
        return true;
      }

      if (item.desc !== null && item.desc.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
        return true;
      }

      if (item.category_name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
        return true;
      }

      return false;
    });

    this.state.productsFiltered = products;

    this.forceUpdate();
  }

  handleEditCategory = (cid, name) => {
    refCategory
      .doc(cid)
      .set({
        name
      }, { merge: true })
      .then(() => {
        this.fetchLocation();
      });
  }

  handleAddCategory = async (e) => {
    if (this.state.verified !== false) {
      const newCategory = prompt('Please enter new category name');
      if (newCategory !== null && newCategory !== "") {
        try {
          const docRef = await addDoc(refCategory, {
            location_id: this.state.lid,
            menu: e.target.getAttribute('menu'),
            name: newCategory
          });
          console.log("Document written with ID: ", docRef.id);
          this.fetchLocation();
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      }
    }
  }

  handleAddProduct = async (e, files_param) => {
    if (this.state.verified !== false) {
      const cid = e.target.getAttribute('cid')
      const pid = e.target.getAttribute('pid')
      const name = e.target.getAttribute('name')
      const price = e.target.getAttribute('price')
      const desc = e.target.getAttribute('desc')
      const files = files_param

      if (name === undefined || name === "" || name === null) {
        alert('Numele este invalid')
        return;
      } else if (isNaN(parseFloat(price)) && price !== null) {
        alert('Pretul este invalid')
        return;
      } else if (pid === undefined || pid === null || pid === "") { //Add new product
        const products = this.state.products
        let max_position = 0
        if (products.length > 0) {
          // const max = products.reduce(function(prev, current) {
          //   return (parseFloat(prev.position) > parseFloat(current.position)) ? prev : current
          // })
          let filtered_products = products.filter(item => item.category_id === cid);
          max_position = filtered_products.length + 1;

        }

        if (cid !== undefined && cid !== "") {
          let product_data = {
            category_id: cid,
            name: name,
            price: price,
            desc: desc,
            position: parseInt(max_position) + 1,
          }
          let pid = ""

          let ref = await addDoc(refProducts, product_data);
          pid = ref.id;

          if (files.length > 0) { //upload files
            let upload_urls = []

            for (let i = 0; i < files.length; i++) {
              const file = files[i]
              // await storage.child(this.state.slug + '/' + Date.now() + '.jpg').put(file).then(async (snap) => {
              // Firebase version 9+

              Resizer.imageFileResizer(
                file, //is the file of the new image that can now be uploaded...
                600, // is the maxWidth of the  new image
                400, // is the maxHeight of the  new image
                'JPEG', // is the compressFormat of the  new image
                100, // is the quality of the  new image
                0, // is the rotatoion of the  new image
                async uri => {
                  console.log(uri, 'upload');

                  let stor = refOfStorage(storage, this.state.slug + '/' + Date.now() + '.jpg');
                  let upload = await uploadBytes(stor, uri);
                  let dldurl = await getDownloadURL(upload.ref);
                  upload_urls.push(dldurl);

                  await upload_urls.forEach(async (url) => {
                    let picture_data = {
                      product_id: pid,
                      location_id: this.state.lid,
                      url: url
                    }

                    // await refPictures.add(picture_data).then((ref) => {
                    try {
                      await addDoc(refPictures, picture_data);
                      console.log('success add product')
                    } catch (error) {
                      console.log("Error adding product:", error);
                    }

                    this.fetchLocation();

                  })
                },
                'blob'
              );
            }


          } else {
            this.fetchLocation();
          }

        }
      } else if (cid !== undefined && cid !== "" && cid !== null) { //Edit product
        let product_data = {
          category_id: cid,
          name: name,
          price: price,
          desc: desc,
        }

        // await refProducts.doc(pid).set(product_data, { merge: true }).then((ref) => {

        // }).catch(function (error) {
        //   console.log("Error editing product:", error);
        // })

        await setDoc(doc(db, 'products', pid), product_data, { merge: true });

        // Save images
        // Delete old ones
        // await refPictures.where('location_id', '==', this.state.lid).where('product_id', '==', pid).get().then(async snaps => {
        let snaps = await getDocs(query(refPictures, where('location_id', '==', this.state.lid), where('product_id', '==', pid)));
        snaps.docs.forEach(async (snap) => {
          let item = snap.data();

          if (files.indexOf(item.url) === -1) {
            // await snap.ref.delete();
            deleteDoc(snap.ref);
            await this.state.pictures.map((pic, index) => {
              if (pic.url === item.url) {
                this.state.pictures.splice(index, 1);
              }
            })
          }
        })

        // Add new
        if (files.length > 0) { //upload files
          let upload_urls = []

          for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (typeof file === 'object') {
              // await storage.child(this.state.slug + '/' + Date.now() + '.jpg').put(file).then(async (snap) => {
              //   await snap.ref.getDownloadURL().then((url) => {
              //     upload_urls.push(url)
              //   })
              // })
              // Firebase version 9+
              Resizer.imageFileResizer(
                file, //is the file of the new image that can now be uploaded...
                600, // is the maxWidth of the  new image
                400, // is the maxHeight of the  new image
                'JPEG', // is the compressFormat of the  new image
                100, // is the quality of the  new image
                0, // is the rotatoion of the  new image
                async uri => {
                  let stor = refOfStorage(storage, this.state.slug + '/' + Date.now() + '.jpg');
                  let upload = await uploadBytes(stor, uri);
                  let dldurl = await getDownloadURL(upload.ref);
                  upload_urls.push(dldurl);

                  await upload_urls.forEach(async (url) => {
                    let picture_data = {
                      product_id: pid,
                      location_id: this.state.lid,
                      url: url
                    }
                    // await refPictures.add(picture_data).then((ref) => {
                    //   console.log('success add product')
                    // }).catch(function (error) {
                    //   console.log("Error adding product:", error);
                    // })
                    try {
                      await addDoc(refPictures, picture_data);
                      console.log('success add product')
                    } catch (error) {
                      console.log("Error adding product:", error);
                    }

                    this.fetchLocation();

                  })
                },
                'blob'
              );
            }
          }
        } else {
          this.fetchLocation();
        }

      }
    }
  }

  handleMoveProduct = (pid, pos, cid) => {
    let filtered_products = this.state.products.filter(item => item.category_id === cid);
    let directionUp;
    let oldPos;
    filtered_products.forEach((fprod, index) => {
      if (fprod.pid === pid) {
        directionUp = fprod.position < pos;
        oldPos = fprod.position
      }
    })

    filtered_products.forEach((fprod, index) => {

      if (fprod.pid === pid) {
        filtered_products[index].position = pos;
      } else {
        if (directionUp && parseInt(fprod.position) >= oldPos && parseInt(fprod.position) <= pos) {
          filtered_products[index].position = parseInt(fprod.position) - 1;
        } else if (!directionUp && parseInt(fprod.position) <= oldPos && parseInt(fprod.position) >= pos) {
          filtered_products[index].position = parseInt(fprod.position) + 1;
        }
      }
    });
    let sorted_products = filtered_products.sort((a, b) => parseFloat(a.position) - parseFloat(b.position));

    let upCount = 0;
    sorted_products.forEach((product, index) => {
      setDoc(doc(db, 'products', product.pid), {
        position: parseInt(index) + 1
      }, { merge: true })
        .then(() => {
          upCount++;

          if (upCount === sorted_products.length) {
            this.fetchLocation();
          }
        })
    })
  }

  handleMoveCategory = (pos, cid, menu) => {
    pos = parseInt(pos);

    let filtered_categories = this.state.categories.filter(item => item.menu === menu);
    let directionUp;
    let oldPos;

    filtered_categories.forEach((fprod, index) => {
      if (oldPos === undefined) {
        fprod.order = index + 1;
        filtered_categories[index].order = fprod.order;
      }

      if (fprod.cid === cid) {
        directionUp = fprod.order > pos;
        oldPos = fprod.order
      }
    })

    filtered_categories.forEach((fprod, index) => {
      if (fprod.cid === cid) {
        filtered_categories[index].order = pos;
      } else {
        if (parseInt(fprod.order) >= oldPos && parseInt(fprod.order) <= pos) {
          filtered_categories[index].order = parseInt(fprod.order) - 1;
        } else if (parseInt(fprod.order) <= oldPos && parseInt(fprod.order) >= pos) {
          filtered_categories[index].order = parseInt(fprod.order) + 1;
        }
      }
    });

    let sorted_categories = filtered_categories.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));

    let upCount = 0;
    sorted_categories.forEach((item, index) => {

      setDoc(doc(db, 'category', item.cid), {
        order: parseInt(index) + 1
      }, { merge: true })
        .then(() => {
          upCount++;

          if (upCount === sorted_categories.length) {
            this.fetchLocation();
          }
        })
    })
  }

  handleDeleteProduct = (pid) => {
    deleteDoc(doc(db, 'products', pid)).then(() => {
      this.fetchLocation();
    })
  }

  handleActivateProduct = (pid) => {
    let activated = null;

    this.state.products.forEach((product, index) => {
      if (product.pid === pid) {
        activated = !this.state.products[index].activated;

        if (this.state.products[index].activated === undefined) {
          activated = false;
        }

        this.state.products[index].activated = activated;
      }
    });

    setDoc(doc(db, 'products', pid), {
      activated
    }, { merge: true })
      .then(() => {
        this.fetchLocation();
      })
  }

  handleActivateCategory = (cid) => {

    let activated = null;

    this.state.categories.forEach((product, index) => {
      if (product.cid === cid) {
        activated = !this.state.categories[index].activated;

        if (this.state.categories[index].activated === undefined) {
          activated = false;
        }

        this.state.categories[index].activated = activated;
      }
    });
    console.log("activated = " + activated);

    setDoc(doc(db, 'category', cid), {
      activated
    }, { merge: true })
      .then(() => {
        this.fetchLocation();
      })
  }

  fetchLocation = async () => {
    let categories = [];
    console.log('fetch location');

    this.loadData(1);

    return;

    if (this.state.lid !== '') {
      await refCategory.where("location_id", "==", this.state.lid).get().then(snaps => {
        snaps.docs.forEach((doc) => {
          const data = doc.data()

          const category = {
            cid: doc.id,
            menu: data.menu,
            name: data.name,
            order: data.order
          }
          categories.push(category)
        })

        categories.sort((a, b) => {
          if (a.order !== undefined && b.order !== undefined) {
            if (a.order < b.order) { return -1; }
            if (a.order > b.order) { return 1; }
            return 0;
          } else {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          }
        });

        this.state.categories = categories;
        this.forceUpdate();
      })
    }

    refProducts.get().then((snap) => {
      let products = []
      snap.docs.forEach((doc) => {
        const data = doc.data()
        const product = {
          pid: doc.id,
          category_id: data.category_id,
          category_name: '',
          name: data.name,
          price: data.price,
          desc: data.desc,
          position: data.position,
        }

        try {
          product.category_name = categories.filter(item => item.cid === data.category_id)[0].name;
        } catch (error) {

        }

        products.push(product);
      })

      this.state.products = products;
      this.state.productsFiltered = products;

      this.setState({ loading: false });
    })

    refPictures.where('location_id', '==', this.state.lid).get().then((snap) => {
      let pictures = []
      snap.docs.forEach((doc) => {
        const picture = doc.data()
        // const picture = {
        //   product_id: data.product_id,
        //   url: data.url
        // }
        pictures.push(picture)
      })
      this.setState({
        pictures: pictures
      })
    })
  }

  fetchLogin = async () => {
    this.setState({ loading: true });

    const slug = this.state.slug
    let uid = ""
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        uid = user.uid
        this.setState({ uid });

        if (this.state.location.uid === uid) {
          this.setState({
            editmode: true,
          });
        }
      }

      if (uid !== "") {
        let snap = await getDoc(doc(db, 'users', uid));

        if (snap.exists()) {
          const data = snap.data();

          if (data.verified === false) {
            this.setState({
              verified: false,
            })
          } else {
            this.setState({
              verified: true
            })
          }
        } else {
          console.log("no exist user data for : " + uid)

          this.setState({
            verified: false,
          })
        }
      } else {
        this.setState({
          verified: true
        })
      }
    })

    let refs = await getDocs(query(refLocations, where('slug', '==', this.state.slug)));


    refs.docs.forEach((ref) => {

      const data = ref.data()
      data.lid = ref.id;
      // console.log(data);

      this.setState({ lid: ref.id }, () => {
        if (data.uid === this.state.uid) {
          this.fetchLocation();
        }
      });

      if (data.uid === this.state.uid) {
        console.log('turn on edit mode')
        this.setState({
          editmode: true,
          className: data.className,
          location: data,
          loading: false
        })
      } else {
        this.setState({
          className: data.className,
          location: data
        });
      }
    });

    if (refs.docs.length === 0) {
      let refs = await getDocs(query(refLocations, where('uid', '==', this.state.slug)));

      if (refs.docs.length > 0) {
        let loc = refs.docs[0].data();

        window.location = '/' + loc.slug;
      }
    }

  }

  editText = (what, e) => {
    if (!this.state.editmode) {
      return;
    }

    // if (e.target.innerText !== undefined) {
    //   e.target.innerText = e.target.innerText.replace('\ufeff', '');
    // }
    let value = e.target.innerText

    if (what === 'footerDesc') {
      this.state.location.footerDesc = value
    } else if (what === 'name') {
      this.state.location.name = value;
    } else if (what === 'facebookUrl') {
      this.state.location.facebookUrl = value;
    }

    clearTimeout(this.saveTimeout);
    this.saveTimeout = setTimeout(() => {

      setDoc(doc(db, 'locations', this.state.lid), {
        name: this.state.location.name,
        footerDesc: this.state.location.footerDesc,
        facebookUrl: this.state.location.facebookUrl,
      }, {
        merge: true
      })
    }, 1000);
  }

  handleFileChange = (e) => {

    const file = e.target.files
    /*this.setState({               //use only this to upload original image without resize.
        files: e.target.files
    })*/
    console.log(file);

    for (let i = 0; i < file.length; i++) {
      Resizer.imageFileResizer(
        file[i], //is the file of the new image that can now be uploaded...
        600, // is the maxWidth of the  new image
        400, // is the maxHeight of the  new image
        'JPEG', // is the compressFormat of the  new image
        100, // is the quality of the  new image
        0, // is the rotatoion of the  new image
        uri => {
          // this.setState({
          //     files: [...this.state.files, uri]
          // })
          // console.log(uri)

          // storage.child(this.state.slug + '/logo/' + Date.now() + '.png').put(uri).then(async (snap) => {
          //   snap.ref.getDownloadURL().then((url) => {
          //     this.state.location.logo = url;
          //     this.forceUpdate();

          //     refLocations
          //       .doc(this.state.location.lid)
          //       .set({
          //         logo: url
          //       }, { merge: true })
          //   })
          // })

          // Firebase version 9+
          let stor = refOfStorage(storage, this.state.slug + '/logo/' + Date.now() + '.jpg');
          uploadBytes(stor, uri).then((upload) => {
            getDownloadURL(upload.ref).then((dldurl) => {
              this.state.location.logo = dldurl;
              this.forceUpdate();

              setDoc(doc(db, 'locations', this.state.lid), {
                logo: dldurl
              }, { merge: true })
            })
          })

        },  // is the callBack function of the new image URI
        'blob' // is the output type of the new image
      )
    }
  }

  changeLogo() {
    if (this.state.editmode) {
      this.fileInput.click();
    }
  }

  scrollTo(where) {
    if (where === 'drinks') {
      window.scrollTo({ top: document.querySelector('#menu-Bauturi').offsetTop, behavior: 'smooth' })
    } else if (where === 'food') {
      window.scrollTo({ top: document.querySelector('#menu-Mancare').offsetTop, behavior: 'smooth' })
    }
  }

  exportToJson(objectData) {
    let filename = objectData.location.name + ".json";
    let contentType = "application/json;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(objectData)))], { type: contentType });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var a = document.createElement('a');
      a.download = filename;
      a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(objectData));
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  async duplicateMenu(initialDoc, finalSlug) {
    debugger
    return;
    refLocations
      .doc(initialDoc)
      .get()
      .then(async documentLocation => {
        let newDocLocation = documentLocation.data();
        newDocLocation.slug = finalSlug;

        // Got initial location
        refLocations
          .add(newDocLocation)
          .then(docRef => {
            // Added new location
            // Get old categories
            refCategory
              .where('location_id', '==', initialDoc)
              .get()
              .then(async snapshotsCategory => {
                // Got old categories
                snapshotsCategory.forEach(async snapshotCategory => {
                  // For each old category
                  let newSnapshotCategory = snapshotCategory.data();
                  newSnapshotCategory.location_id = docRef.id;

                  let catRef = await refCategory.add(newSnapshotCategory); // Add new cat
                  let snapshotsProducts = await refProducts.where('category_id', '==', snapshotCategory.id).get(); // Get cat products
                  snapshotsProducts.forEach(async snapshotProduct => {
                    // For each product old
                    let productData = snapshotProduct.data();
                    productData.category_id = catRef.id;

                    let prodRef = await refProducts.add(productData);
                    let snapshotsPictures = await refPictures.where('product_id', '==', snapshotProduct.id).get();
                    snapshotsPictures.forEach(async snapshotPicture => {
                      // Each picture
                      let pictureData = snapshotPicture.data();
                      pictureData.product_id = prodRef.id;
                      pictureData.location_id = docRef.id;

                      // Add picture
                      let res = await refPictures.add(pictureData);
                      let a = 1;
                    })
                  });
                });
              });
          })
      });
  }

  render() {
    let footerClass = this.state.editmode ? 'footer-desc editable-input' : 'footer-desc';
    let verifyMessage, loadingStr;
    let menus = [];
    let location;

    if (!this.state.infoMode) {
      location = this.state.location;

      if (this.state.slug === "" || (this.state.verified === undefined && this.state.editmode)) {
        return <div></div>
      }

      if (this.state.verified === false) {
        // verifyMessage = 
        return (
          <div className="verify-warning container">
            <div className="warning-paragraph">Contul tau este in curs de verificare.</div>
            <div className="warning-paragraph">Te vom anunta cand va fi aprobat.</div>

            <button className='quick-button quick-button-small' style={{ alignSelf: 'center' }} onClick={() => {
              if (window.confirm('Esti sigur ca vrei sa iesi din cont?')) {
                auth.signOut().then(() => {
                  window.location = '/login'
                })
              }
            }}>Log out</button>
          </div>);
      }

      let menu_list = ["Bauturi", "Mancare"]
      menu_list.forEach((menu_name, index) => {
        menus.push(<Menu key={"menu" + index}
          name={menu_name}
          categories={this.state.categories}
          products={this.state.productsFiltered}
          pictures={this.state.pictures}
          verified={this.state.verified}
          editmode={this.state.editmode}
          slug={this.state.slug}
          search={this.state.search}
          handleAddCategory={this.handleAddCategory}
          handleEditCategory={this.handleEditCategory}
          handleAddProduct={this.handleAddProduct}
          handleMoveProduct={this.handleMoveProduct}
          handleMoveCategory={this.handleMoveCategory}
          handleDeleteProduct={this.handleDeleteProduct}
          handleActivateProduct={this.handleActivateProduct}
          handleActivateCategory={this.handleActivateCategory}
        />)
      })
      loadingStr = 'Se incarca meniul';

      if (this.state.location.name !== undefined) {
        loadingStr = 'Se incarca ' + this.state.location.name;
      }
      if (this.state.products.length > 0 && this.state.location.name !== undefined) {
        // this.exportToJson(this.state)
      }

      if (this.state.duplicatedit === false) {
        // console.log("Duplicated it");

        // Duplicate it
        // this.duplicateMenu('rJHAHODhYofKb680rna', 'thebricksen');
        // this.duplicateMenu('qWktpbXZJXpl009UNkH', 'opus9_valori');


        this.state.duplicatedit = true;
      }

    } else {
      location = this.state.infoLocation;
    }

    // Format
    setTimeout(() => {
      // console.log(this.state)

      // let prds = this.state.products;
      // let lastCat = "";
      // let lg = ``;

      // prds.forEach(pr => {
      //   if (pr.category_name !== lastCat) {
      //     lastCat = pr.category_name;

      //     lg += `<h1>${pr.category_name}<h1>`;
      //   }

      //   lg += `<h3 style="display: inline">${pr.name}</h3>  <b>${pr.price} lei</b>`;

      //   if (pr.desc != null && pr.desc.length > 0)
      //   lg += `<p>${pr.desc}</p>`;
      // });

      // console.log(lg);
    }, 5000);

    return (
      <div className="App" style={{ minHeight: window.innerHeight }}>
        <link rel="stylesheet" href={`/css/${this.state.className}.css?a=2`} />
        {this.state.loading &&
          <div style={{ paddingTop: 100 }}>
            <Spinner animation="grow" variant="success" />
            <div>{loadingStr}</div>
          </div>
        }
        <Helmet>
          <title>{'Meniu ' + (location.name !== undefined ? location.name : '')}</title>
        </Helmet>
        <div className="container">
          {verifyMessage}
          <header className={"App-header " + (this.state.infoMode && ' info-app-header')}>
            {this.state.infoMode && location.logo !== undefined &&
              <img src={location.logo} className="App-logo" alt="logo" onClick={() => this.changeLogo()} />}
            {location.logo !== undefined && !this.state.infoMode ?
              <img src={location.logo} className="App-logo" alt="logo" onClick={() => this.changeLogo()} />
              :
              <div className={"text-logo " + (this.state.editmode && !this.state.infoMode ? ' editable-input' : ' info-text-logo')} onClick={() => this.changeLogo()}>{location.name}</div>
            }
            <input type="file" accept="image/*" ref={fileInput => this.fileInput = fileInput} style={{ display: 'none' }} multiple onChange={this.handleFileChange} />
          </header>

          {this.state.infoMode &&
            <div className="info-buttons row mx-2">
              <div className="info-small-buttons-container w-100">
                {location.phone !== undefined && location.phone !== null &&
                  <a href={"tel:" + location.phone} role="button" className="btn quick-button mb-2" target={'_blank'} rel={'noopener noreferrer'}>
                    <FontAwesomeIcon icon={faPhoneAlt} /> Suna
                  </a>}
                {location.messengerUrl !== undefined && location.messengerUrl !== null &&
                  <a href={location.messengerUrl} role="button" className="btn quick-button mb-2" target={'_blank'} rel={'noopener noreferrer'}>
                    <FontAwesomeIcon icon={faFacebookMessenger} /> Messenger
                  </a>}
                {location.email !== undefined && location.email !== null &&
                  <a href={"mailto:" + location.email} role="button" className="btn quick-button mb-2" target={'_blank'} rel={'noopener noreferrer'}>
                    <FontAwesomeIcon icon={faAt} /> Email
                  </a>}
                {location.facebookUrl !== undefined && location.facebookUrl !== null &&
                  <a href={location.facebookUrl} role="button" className="btn quick-button mb-2" target={'_blank'} rel={'noopener noreferrer'}>
                    <FontAwesomeIcon icon={faFacebookF} /> Facebook
                  </a>}
                {location.directionUrl !== undefined && location.directionUrl !== null &&
                  <a href={location.directionUrl} role="button" className="btn quick-button mb-2" target={'_blank'} rel={'noopener noreferrer'}>
                    <FontAwesomeIcon icon={faMapMarker} /> Harta
                  </a>}
                {/* {location.tripadvisorUrl !== undefined && location.tripadvisorUrl !== null &&
                    <a href={location.tripadvisorUrl} role="button" className="btn quick-button mb-2" target={'_blank'} rel={'noopener noreferrer'}>
                      <FontAwesomeIcon icon={faTripadvisor} /> TripAdvisor
                    </a>} */}
                {location.ialocUrl !== undefined && location.ialocUrl !== null &&
                  <a href={location.ialocUrl} role="button" className="btn quick-button mb-2" target={'_blank'} rel={'noopener noreferrer'}>
                    <FontAwesomeIcon icon={faCalendarAlt} /> Rezerva
                  </a>}
                {location.roomReservationUrl !== undefined && location.roomReservationUrl !== null &&
                  <a href={location.roomReservationUrl} role="button" className="btn quick-button mb-2" target={'_blank'} rel={'noopener noreferrer'}>
                    <FontAwesomeIcon icon={faCalendarAlt} /> Rezerva
                  </a>}
                {location.takeawayUrl !== undefined && location.takeawayUrl !== null &&
                  <a href={location.takeawayUrl} role="button" className="btn quick-button mb-2" target={'_blank'} rel={'noopener noreferrer'}>
                    <FontAwesomeIcon icon={faHome} /> Takeaway
                  </a>}
                {location.foodpandaUrl !== undefined && location.foodpandaUrl !== null &&
                  <a href={location.foodpandaUrl} role="button" className="btn quick-button mb-2" target={'_blank'} rel={'noopener noreferrer'}>
                    <FontAwesomeIcon icon={faHome} /> Foodpanda
                  </a>}
                {location.appUrl !== undefined && location.appUrl !== null &&
                  <a href={location.appUrl} role="button" className="btn quick-button mb-2" target={'_blank'} rel={'noopener noreferrer'}>
                    <FontAwesomeIcon icon={faMobileAlt} /> Aplicatie
                  </a>}
              </div>

              <a href={"https://meniu.la/" + this.state.slug} role="button" target={'_blank'} rel={'noopener noreferrer'} className={"btn quick-button info-menu-button mt-3"}>
                <FontAwesomeIcon icon={faUtensils} /> Meniu
              </a>

              <div className={'w-100'} style={{ marginTop: 50 }}>
                <div style={{ fontSize: 'small' }}>
                  {location.phone !== undefined && location.phone !== null &&
                    <a href={"tel:" + location.phone} className="btn mb-0 pb-0" target={'_blank'} rel={'noopener noreferrer'}>
                      <FontAwesomeIcon icon={faPhoneAlt} /> {location.phone}
                    </a>}
                  {location.email !== undefined && location.email !== null &&
                    <a href={"mailto:" + location.email} className="btn" target={'_blank'} rel={'noopener noreferrer'}>
                      <FontAwesomeIcon icon={faAt} /> {location.email}
                    </a>}
                  {location.address !== undefined && location.address !== null &&
                    <div>
                      <FontAwesomeIcon icon={faMapMarker} /> {location.address}
                    </div>}
                </div>
              </div>
            </div>}

          {!this.state.infoMode &&
            <div style={{ height: 65 }} className={'search-container'}>
              <input
                id="search"
                className="search"
                placeholder="Cauta in meniu..."
                onChange={(e) => this.search(e.target.value)}
              />
            </div>}

          {!this.state.infoMode &&
            <body>
              <div className={'text-center buttons-container'}>
                <button className='quick-button' onClick={() => this.scrollTo('drinks')}>Bautura</button>
                <button className='quick-button' onClick={() => this.scrollTo('food')}>Mancare</button>
              </div>
              {menus}
            </body>}

          {!this.state.infoMode &&
            <footer>
              {/* {location.logo !== undefined ?
                    <img src={location.logo} className={"footer-logo" + (this.state.editmode && ' editable-input')} alt="logo" onClick={() => this.changeLogo()} />
                  :
                    <div className={"text-logo" + (this.state.editmode && ' editable-input')} onClick={() => this.changeLogo()}>{location.name}</div>
                  }
                  <input type="file" accept="image/*" ref={fileInput => this.fileInput = fileInput} style={{display: 'none'}} multiple onChange={this.handleFileChange} /> */}

              <div className={"text-logo"}>{location.name}</div>

              {location.facebookUrl !== undefined && location.facebookUrl.length > 5 &&
                <iframe
                  title={location.facebookUrl}
                  src={`https://www.facebook.com/plugins/page.php?href=${location.facebookUrl}&tabs=timeline&width=340&height=400&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=606480460131776`}
                  width="340"
                  height="400"
                  scrolling="no"
                  frameBorder="0"
                  allowTransparency="true"
                  allow="encrypted-media"
                />}

              {this.state.editmode &&
                <div style={{ fontSize: 12, textAlign: 'left', marginLeft: 15, marginTop: 20 }}>
                  {location.facebookUrl === '' ? 'Adresa paginii de Facebook (ex: https://facebook.com/pagina-mea)' : ''}
                </div>}
              {this.state.editmode &&
                <div className={footerClass + ' input-footer-text'}>
                  <div contentEditable={this.state.editmode} onInput={(e) => this.editText('facebookUrl', e)} onBlur={() => this.forceUpdate()}>
                    {location.facebookUrl}
                  </div>
                </div>}

              {this.state.editmode &&
                <div style={{ fontSize: 12, textAlign: 'left', marginLeft: 15 }}>
                  {location.name === '' ? 'Numele locatiei' : ''}
                </div>}
              {this.state.editmode &&
                <div className={footerClass + ' input-footer-text'}>
                  <div contentEditable={this.state.editmode} onInput={(e) => this.editText('name', e)} onBlur={() => this.forceUpdate()}>
                    {location.name}
                  </div>
                </div>}

              {this.state.editmode &&
                <div style={{ fontSize: 12, textAlign: 'left', marginLeft: 15 }}>
                  {location.footerDesc !== undefined && location.footerDesc.length < 2 ? 'Descriere, program, telefon, detalii, etc...' : ''}
                </div>}
              <div className={footerClass} style={{ whiteSpace: 'pre' }}>
                <div contentEditable={this.state.editmode} onInput={(e) => this.editText('footerDesc', e)} onBlur={() => this.forceUpdate()}>
                  {location.footerDesc}
                </div>
              </div>
              {/* <div className="footer-sns">
                    <div className="footer-sns-body">
                      <SocialLink link="https://www.facebook.com" image="facebook" />
                      <SocialLink link="https://www.instagram.com" image="instagram" />
                      <SocialLink link="https://www.telegram.com" image="telegram" />
                      <SocialLink link="#" image="phone" />
                    </div>
                  </div> */}
              <div className="footer-url">
                <div>
                  <b><font color="grey">www.meniu.la/</font><font color="black">{location.slug}</font></b>
                </div>
              </div>

              {this.state.editmode ?
                <button className='quick-button quick-button-small' onClick={() => {
                  if (window.confirm('Esti sigur ca vrei sa iesi din cont?')) {
                    auth.signOut().then(() => {
                      window.location = '/login'
                    })
                  }
                }}>Log out</button>
                :
                <div>
                  <a href="https://meniu.la" target={'_blank'} rel={'noopener noreferrer'}>Meniu.la - Meniuri Digitale</a>
                </div>}

              {this.state.editmode &&
                <div className={'mt-5 text-left'}>
                  {/* <div style={{ fontSize: 'small' }}>
                    <span><b>Noutati Meniu.la:</b></span> <br/>
                    <span>- Imbunatatire viteza de incarcare (28 august 2020)</span>
                  </div>
                  <br/> */}
                  <div style={{ fontSize: 'small' }}>
                    <div className={'text-center'}>
                      <a href="https://wa.me/40758850182">Suport Whatsapp</a><br />
                      <i>Doar administratorii paginii vad acest mesaj.</i>
                    </div>
                  </div>
                </div>}
            </footer>}
        </div>


        {/* <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/css/${this.state.className}.css?a=1`} /> */}
      </div>
    )
  };
};

export default LocationPage;